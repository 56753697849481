<template>
  <div>
    <vs-sidebar
      :hidden-background="hiddenBaground"
      class="sidebarx agendaSidebar"
      click-not-close
      color="primary"
      default-index="1000"
      position-right
      spacer
      v-model="active"
    >
      <div class="event">
        <h3 class="titreRDV">Evénement</h3>
        <div class="nego">
          <b>Négociateur : </b>
          {{negociateurInfos}}
        </div>
        <vs-input
          class="w-full"
          label-placeholder="Titre *"
          name="event-name"
          v-model="title"
        />
        <vs-select
          label="Type"
          v-model="eventForm.type.value"
        >
          <vs-select-item
            :key="key"
            :value="item.value"
            :text="item.text"
            v-for="(item, key) in eventForm.type.options"
          />
        </vs-select>

        <div class="my-4">
          <table class="detailsRDV">
            <tr>
              <td >
                <small class="date-label">Date <sup>*</sup></small><br>
                <flat-pickr
                  :config="configdatePicker"
                  class="w-full mr-5"
                  placeholder="Date debut"
                  v-model="start"
                />
              </td>
              <td >
                <small class="date-label">Heure<sup>*</sup></small><br>
                <vue-timepicker class=" w-full" format="HH:mm" v-model="startTime" />
              </td>
            </tr>
          </table>

          <small class="date-label">Durée</small><br>
          <select class="vs-select vx-col w-12/12" v-model="minutes">
            <option selected value="60">60 minutes</option>
            <option
              :key="item.key"
              :value="item.key"
              v-for="item in minutesOptions"
            >
              {{item.value}}
            </option>
          </select>
        </div>
        <textarea
          class="w-full textarea"
          placeholder="Description et commentaires"
          type="text"
          v-model="description"
        />
      </div>
      <div class="ad">
        <!-- Add rdv -->
        <div v-if="newRdv">
          <small class="date-label">Commande<sup>*</sup></small><br>
          <select class="vs-select vx-col w-12/12" v-model="commande">
            <option selected value="">Réference commande</option>
            <option
              :key="item.key"
              :value="item.key"
              v-for="item in commandesOptions"
            >
              {{item.value}}
            </option>
          </select>
          <small class="date-label">Annonce</small> <br>
          <autocomplete
            :request-headers="authHeaders"
            :results-display="formattedAdDisplay"
            :source="adEndpoint"
            class="vx-col w-12/12"
            placeholder="Numéro de télephone"
            ref="autocomplete"
            results-property="hydra:member"
            results-value="id"
            style="margin-right: 20px"
            v-model="referenceAd">
          </autocomplete>
          <!-- Afficher les détails de l'annonce quand on on choisit la réference -->
          <div v-if="addAnnonce">
            <div class="header">
              <img :src="addAnnonce.photo" class="imageRdv">
              {{addAnnonce.tradeType.name}}<br>
              <b>Réf :</b> {{addAnnonce.reference}}<br>
              {{addAnnonce.surface}} m<sup>2</sup><br>
              {{addAnnonce.price | priceFormat}}<br>
              <span class="sep"></span>
              {{addAnnonce.description}}<br>
              <a :href="addAnnonce.adUrl" class="adLinkDetails">
                <feather-icon class="mr-2" icon="LinkIcon" svgClasses="w-4 h-4"/>
                Lien de l'annonce</a>
            </div>

            <div class="corpsRDV mt-6">
              <feather-icon class="mr-2" icon="UserIcon" svgClasses="w-full h-4"/>
              <b>Vendeur </b><br>
              <small class="date-label">Nom et prénom du vendeur</small><br>
              <vs-input class="w-full" name="nomVendeur" v-model="nomVendeur"></vs-input>
              <small class="date-label">Adresse du bien</small><br>
              <vs-input class="w-full" name="adresseBien" v-model="adresseBien"></vs-input>
              <br>
              <feather-icon class="mr-2" icon="MapIcon" svgClasses="w-4 h-4"/>
              {{addAnnonce.postalCode}} {{addAnnonce.adCity}}
              <br>
              <feather-icon class="mr-2" icon="PhoneCallIcon" svgClasses="w-4 h-4"/>
              {{addAnnonce.mobileNumber}}
            </div>
          </div>
        </div>
        <!-- Modifier rdv -->
        <div v-else >
          <div v-if="rendezVous">
            <p class="idAd">
              Rendez vous : <span>{{rendezVous.id}}</span> - Commande
              <span v-if="rendezVous.commande">
                <router-link
                  :to="{ name: 'admin-orders-id', params: { id: rendezVous.commande.id }}"
                >
                  {{rendezVous.commande.reference}}
                </router-link>
              </span>
            </p>
            <div v-if="rendezVous.ad">
              <div class="header">
                <img :src="rendezVous.ad.photo" class="imageRdv">
                {{rendezVous.ad.tradeType.name}}<br>
                <b>Réf :</b> {{rendezVous.ad.reference}}<br>
                {{rendezVous.ad.surface}} m<sup>2</sup><br>
                {{rendezVous.ad.price | priceFormat}}<br>
                <span class="sep"></span>
                {{rendezVous.ad.description}}<br>
                <a :href="rendezVous.ad.adUrl" class="adLinkDetails">
                  <feather-icon class="mr-2" icon="LinkIcon" svgClasses="w-4 h-4"/>
                  Lien de l'annonce
                </a>
              </div>

              <div class="corpsRDV mt-6">
                <feather-icon class="mr-2" icon="UserIcon" svgClasses="w-full h-4"/>
                <b>Vendeur </b><br>
                <small class="date-label">Nom et prénom du vendeur</small><br>
                <vs-input class="w-full" name="nomVendeur" v-model="rendezVous.ad.nomVendeur" />
                <small class="date-label">Adresse du bien</small><br>
                <vs-input class="w-full" name="adresseBien" v-model="rendezVous.ad.adresseBien" />
                <br>

                <feather-icon class="mr-2" icon="MapIcon" svgClasses="w-4 h-4"/>
                {{rendezVous.ad.postalCode}} {{rendezVous.ad.adCity}}
                <br>
                <feather-icon class="mr-2" icon="PhoneCallIcon" svgClasses="w-4 h-4"/>
                {{rendezVous.ad.mobileNumber}}
              </div>
              <div class=" mt-6">
                <span class="statut">
                  <feather-icon
                    class="mr-2"
                    icon="ApertureIcon"
                    svgClasses="w-4 h-4"
                  />
                  <b>Statut : </b>
                  <v-select
                    :options="optionsStatut"
                    placeholder="Statut"
                    v-model="rendezVous.statut"
                  />
                </span>
              </div>

              <feather-icon class="mr-2" icon="FileTextIcon" svgClasses="w-4 h-4"/>
              <small class="date-label">Numéro du mandat :</small>
              <vs-input
                :disabled="rendezVous.statut !== 'Réalisé'"
                @change="validerLongeurMandat"
                class="w-full"
                type="text"
                v-model="rendezVous.mandat"
              />
              <div class="mt-10" v-if="rendezVous.teleconseiller">
                <b>Téleconseiller : </b>
                {{rendezVous.teleconseiller.firstName}} {{rendezVous.teleconseiller.lastName}}
                <br>Dernière mise à jour <b>: {{dateMiseAjour}} </b>Par <b>{{ whoUpdated}}</b>
              </div>

            </div>
          </div>
          <div v-else >
            <small class="date-label">Commande</small><br>
            <select class="vs-select vx-col w-12/12" v-model="commande">
              <option selected value="">Réference commande</option>
              <option
                :key="item.key"
                :value="item.key"
                v-for="item in commandesOptions"
              >
                {{item.value}}
              </option>
            </select>
            <small class="date-label">Annonce</small> <br>
            <autocomplete
              :request-headers="authHeaders"
              :results-display="formattedAdDisplay"
              :source="adEndpoint"
              class="vx-col w-12/12"
              placeholder="Numéro de télephone"
              ref="autocomplete"
              results-property="hydra:member"
              results-value="id"
              style="margin-right: 20px"
              v-model="referenceAd">
            </autocomplete>

            <div v-if="addAnnonce">
              <div class="header">
                <img :src="addAnnonce.photo" class="imageRdv">
                {{addAnnonce.tradeType.name}}<br>
                <b>Réf :</b> {{addAnnonce.reference}}<br>
                {{addAnnonce.surface}} m<sup>2</sup><br>
                {{addAnnonce.price | priceFormat}}<br>
                <span class="sep"></span>
                {{addAnnonce.description}}<br>
                <a :href="addAnnonce.adUrl" class="adLinkDetails">
                  <feather-icon class="mr-2" icon="LinkIcon" svgClasses="w-4 h-4"/>
                  Lien de l'annonce
                </a>
              </div>

              <div class="corpsRDV mt-6">
                <feather-icon class="mr-2" icon="UserIcon" svgClasses="w-full h-4"/>
                <b>Vendeur </b><br>
                <small class="date-label">Nom et prénom du vendeur</small><br>
                <vs-input class="w-full" name="nomVendeur" v-model="nomVendeur"></vs-input>
                <small class="date-label">Adresse du bien</small><br>
                <vs-input class="w-full" name="adresseBien" v-model="adresseBien"></vs-input>
                <br>

                <feather-icon class="mr-2" icon="MapIcon" svgClasses="w-4 h-4"/>
                {{addAnnonce.postalCode}} {{addAnnonce.adCity}}
                <br>
                <feather-icon class="mr-2" icon="PhoneCallIcon" svgClasses="w-4 h-4"/>
                {{addAnnonce.mobileNumber}}
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="footer-sidebar" slot="footer">
        <vs-button @click="editEvent()"  color="warning" v-if="!newRdv">Modifier</vs-button>
        <vs-button  @click="removeEvent()" color="danger" v-if="!newRdv">Supprimer
        </vs-button>
        <vs-button  @click="addEvent()" v-else>Ajouter</vs-button>
        <vs-button @click="clearAndClose()" class="float-right" type="border">Annuler</vs-button>
      </div>
    </vs-sidebar>

    <div id="simple-calendar-app">
      <div class="vx-row">
        <autocomplete
          :request-headers="authHeaders"
          :results-display="formattedDisplay"
          :source="sourceEndpoint"
          class="vx-col w-4/12 float-left"
          placeholder="Nom du Negociateur"
          results-property="hydra:member"
          results-value="id"
          style="margin-right: 20px"
          v-model="negociateur">
        </autocomplete>
        <select class="vs-select vx-col w-4/12 mr-20 " v-model="commandeFilter">
          <option selected value="">Réference commande</option>
          <option :key="item.key" :value="item.key" v-for="item in commandesOptions">
            {{item.value}}
          </option>
        </select>

        <span class="float-right font-600">
          <span class="color-primary">Négociateur : </span>{{nomNegociateur}}
        </span>
      </div>

      <div class="vx-row mt-10 mb-10 ">
          <div class="vx-col w-3/12 ">
            <small>
              Pour une utilisation plus rapide de l'outil,
              Veuillez récupérer seulement une partie des évenements :
            </small>
          </div>
          <div class="vx-col  w-3/12 ">
            <flat-pickr :config="configdateTimePicker" placeholder="Date début"
                      v-model="dateDebutAgenda"  />
          </div>
          <div class="vx-col w-3/12 ">
          <flat-pickr :config="configdateTimePicker" placeholder="Date Fin"
                      v-model="dateFinAgenda" />
          </div>
          <div class="vx-col  w-3/12 ">
            <vs-button
              @click="updateData()"
              class="vs-component vs-button mb-4 md:mb-0 vs-button-primary vs-button-filled"
              :disabled="checkFilter"
            >
              Chercher
            </vs-button>
            <vs-button
              :disabled="disabledAddButton"
              @click="promptAddNewEvent(new Date())"
              class="addBtn"
              icon="icon-plus"
              icon-pack="feather"
              radius size="large"
            />
          </div>
      </div>
      <div class="vx-card no-scroll-content">
        <vue-cal :disable-views="['years', 'year']"
          :events="simpleCalendarEvents"
          :on-event-click="openEditEvent"
          :selected-date="today"
          class="vuecal--full-height-delete"
          locale="fr"
        />
      </div>

    </div>
  </div>
</template>

<script>
import moduleCalendar from '@/store/calendar/moduleCalendar';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import 'vue-cal/dist/drag-and-drop';
import 'vue-cal/dist/i18n/fr';
import Autocomplete from 'vuejs-auto-complete';
import moment from 'moment';
import vSelect from 'vue-select';
import VueTimepicker from 'vue2-timepicker';
import { French } from 'flatpickr/dist/l10n/fr';

require('vue2-timepicker/dist/VueTimepicker.css');
require('vue-simple-calendar/static/css/default.css');
require('@/assets/css/custom.css');

export default {
  name: 'AdminDiary',
  components: {
    Autocomplete,
    VueCal,
    'v-select': vSelect,
    flatPickr,
    VueTimepicker,
  },
  props: {
    idNego: {
      type: String,
      default: () => '',
    },
  },
  filters: {
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(2).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }

      return '';
    },
  },
  data() {
    return {
      eventForm: {
        type: {
          value: 'physique',
          options: [
            { value: 'physique', text: 'Rendez-vous physique' },
            { value: 'telephonique', text: 'Rendez-vous téléphonique' },
          ],
        },
      },
      loading: false,

      dateDebut: '',
      dateFin: '',
      hiddenBaground: true,
      dateDebutAgenda: '',
      dateFinAgenda: '',
      nomVendeur: '',
      adresseBien: '',
      active: false,
      commandeFilter: '',
      optionsStatut: ['A venir', 'A statuer', 'Réalisé', 'Annulé', 'En attente d\'annulation'],
      negociateur: '',
      negociateurInfos: '',
      commande: '',
      today: new Date(),
      showDate: new Date(),
      configdatePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        locale: French,
      },
      configdateTimePicker: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        locale: French,
      },
      startTime: '',
      disabledFrom: false,
      id: 0,
      title: '',
      titre: '',
      start: '',
      end: '',
      label: '',
      newRdv: true,
      description: 'Description',
      rendezVous: '',
      desc: '',
      url: '',
      referenceAd: '',
      activePromptEditEvent: false,
      trigrammeNego: '',
      disabledAddButton: true,
      commandesOptions: [],
      dateMiseAjour: '',
      whoUpdated: '',
      addAnnonce: '',
      refCommande: '',
      minutes: 60,
      nomNegociateur: '',
      minutesOptions: [
        { key: 75, value: '1 heure et 15 minutes' },
        { key: 90, value: '1 heure 30 minutes' },
        { key: 105, value: '1 heure et 45 minutes' },
        { key: 120, value: '2 heures' },
        { key: 135, value: '2 heures et 15 minutes' },
        { key: 150, value: '2 heures 30 minutes' },
        { key: 165, value: '2 heures et 45 minutes' },
        { key: 180, value: '3 heures' },
        { key: 195, value: '3 heures et 15 minutes' },
        { key: 210, value: '3 heures 30 minutes' },
        { key: 225, value: '3 heures et 45 minutes' },
        { key: 240, value: '4 heures' },
      ],
    };
  },
  watch: {
    negociateur(val) {
      this.commandesOptions = [];
      this.getAgentOrders(val);
    },
    nomVendeur(val) {
      this.title = `Rendez-vous avec ${val}`;
    },
    referenceAd(val) {
      if (val) {
        this.getAnnonce(val);
      }
    },
    commande(val) {
      this.refCommande = val;
    },
  },
  computed: {
    checkFilter() {
      return this.negociateur === '';
    },
    authHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      };
    },
    simpleCalendarEvents() {
      return this.$store.state.calendar.events;
    },
    validForm() {
      return this.title !== '' && this.start !== '' && this.refCommande !== '';
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    validerLongeurMandat() {
      if (this.rendezVous.mandat.length !== 6) {
        this.rendezVous.mandat = '';
        this.$toasted.show('Le numéro de mandat doit contenir 6 caractéres!').goAway(1500);
      }
    },
    clearAndClose() {
      this.title = '';
      this.addAnnonce = '';
      this.refCommande = '';
      this.nomVendeur = '';
      this.adresseBien = '';
      this.active = false;
      this.referenceAd = '';
      this.rendezVous = null;
      this.minutes = 60;
      this.eventForm.type.value = 'physique';
    },
    async getAnnonce(id) {
      const { data } = await this.$http.get(`/api/ads/${id}`);
      this.addAnnonce = data;
      this.title = `Rendez-vous pour la commande ${this.refCommande}, Annonce : ${data.reference}`;
    },
    sourceEndpoint(input) {
      return `${process.env.VUE_APP_BASE_URL}/api/mandataries?lastName=${input}`;
    },
    adEndpoint(input) {
      return `${process.env.VUE_APP_BASE_URL}/api/annonces?mobileNumber=${input}`;
    },
    formattedDisplay(resultat) {
      return `${resultat.firstName} ${resultat.lastName}`;
    },
    formattedAdDisplay(resultat) {
      return `${resultat.mobileNumber} | ${resultat.adCity} ${resultat.postalCode}`;
    },
    async updateData() {
      this.loading = true;
      this.$vs.loading({ type: 'point' });

      this.idNego = this.negociateur;
      this.disabledAddButton = false;

      const dateDebutAgenda = this.dateDebutAgenda || '1-1-2021';
      const dateFinAgenda = this.dateFinAgenda || '1-1-2050';

      try {
        await this.$store.dispatch('calendar/fetchEvents', {
          idNegociateur: this.idNego,
          idOrder: this.commandeFilter,
          startDate: dateDebutAgenda,
          endDate: dateFinAgenda,
        });
      } catch (error) {
        this.$vs.notify({
          title: 'erreur',
          text: this.$parseError(error).message,
          color: 'danger',
        });
      } finally {
        this.loading = false;
        this.$vs.loading.close();
      }
    },
    async addEvent() {
      // Si le formulair n'est pas valide
      if (!this.validForm) {
        this.$vs.notify({
          title: 'Alerte',
          text: 'Veuillez remplir les champs obligatoires',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 5000,
        });

        return;
      }

      // Si le formulaire est valide...
      const appointmentEventParams = {
        user: this.idNego,
        title: this.title,
        start: `${this.start} ${this.startTime}`,
        end: moment(`${this.start} ${this.startTime}`).add(Number(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        description: this.description,
        invId: this.invId,
      };

      if (this.nomVendeur && this.adresseBien) {
        Object.assign(appointmentEventParams, {
          description: `Nom vendeur: ${this.nomVendeur}, Adresse :${this.adresseBien}, ${this.addAnnonce.postalCode} ${this.addAnnonce.adCity}, ${this.description}`,
        });
      }

      if (this.referenceAd) {
        this.$http.put(`/api/ads/${this.referenceAd}`, {
          nomVendeur: this.nomVendeur,
          adresseBien: this.adresseBien,
        });
      }

      const { data } = await this.$http.post(`/api/evenement/addAppointment/${this.idNego}`, appointmentEventParams);
      if (this.commande) {
        this.addAppointment(data);

        // Création d'un rendez-vous
        this.clearAndClose();
      }

      this.$vs.notify({
        title: 'Succès',
        text: 'L\'évenement a été crée!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });
    },
    editEvent() {
      const event = {
        user: this.idNego,
        id: this.id,
        title: this.title,
        start: `${this.start} ${this.startTime}`,
        end: moment(`${this.start} ${this.startTime}`).add(Number(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        description: this.description,
        invId: this.invId,
      };

      this.$store.dispatch('calendar/editEvent', event);
      if (this.rendezVous) {
        const appointment = {
          user: this.idNego,
          id: this.id,
          statut: this.rendezVous.statut,
          commentaire: this.description,
          mandat: this.rendezVous.mandat,
          idRdv: this.rendezVous.id,
          dateDebut: `${this.start} ${this.startTime}`,
          dateFin: moment(`${this.start} ${this.startTime}`).add(Number(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          type: this.eventForm.type.value,
        };

        this.$store.dispatch('calendar/editAppointment', appointment);

        if (this.rendezVous.ad) {
          const announcement = {
            nomVendeur: this.rendezVous.ad.nomVendeur,
            adresseBien: this.rendezVous.ad.adresseBien,
          };
          this.$http.put(`/api/ads/${this.rendezVous.ad.id}`, announcement).then(() => {
            this.clearAndClose();
          });
        }
      }

      if (this.referenceAd) {
        const appointment = {
          ad: `/api/ads/${this.referenceAd}`,
          negociateur: `/api/mandataries/${this.idNego}`,
          commande: `/api/commandes/${this.commande}`,
          statut: 'A venir',
          evenement: this.id,
          commentaire: `Nom vendeur: ${this.nomVendeur}, Adresse :${this.adresseBien}${this.addAnnonce.postalCode} ${this.addAnnonce.adCity}, ${this.description}`,
          teleconseiller: `/api/employees/${this.$store.state.AppActiveUser.user.id}`,
          dateDebut: this.start,
          dateFin: moment(`${this.start} ${this.startTime}`).add(Number(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          dateCreation: moment().format('YYYY-MM-DD HH:mm:ss'),
          type: this.eventForm.type.value,
        };

        this.$store.dispatch('calendar/addAppointment', appointment);

        const announcement = {
          nomVendeur: this.nomVendeur,
          adresseBien: this.adresseBien,
        };
        this.$http.put(`/api/ads/${this.referenceAd}`, announcement).then(() => {
          this.clearAndClose();
        });
      }

      this.activePromptEditEvent = false;
      this.$vs.notify({
        title: 'Succès',
        text: 'L\'évenement a été mis à jour!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });
    },
    removeEvent() {
      const obj = {
        user: this.idNego,
        id: this.id,
        invId: this.invId,
      };
      if (this.rendezVous) {
        this.$store.dispatch('calendar/removeAppointment', this.rendezVous.id);
      }
      this.$store.dispatch('calendar/removeEvent', obj);
      this.$vs.notify({
        title: 'Succès',
        text: 'L\'évenement a été supprimé!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success',
        time: 5000,
      });
      this.active = false;
    },
    addAppointment(event) {
      const appointmentParams = {
        negociateur: `/api/mandataries/${this.idNego}`,
        teleconseiller: `/api/employees/${this.$store.state.AppActiveUser.user.id}`,
        commande: `/api/commandes/${this.commande}`,
        statut: 'A venir',
        commentaire: this.description,
        dateDebut: `${this.start} ${this.startTime}`,
        dateFin: moment(`${this.start} ${this.startTime}`).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss'),
        dateCreation: moment().format('YYYY-MM-DD HH:mm'),
        evenement: event,
        type: this.eventForm.type.value,
      };

      if (this.nomVendeur && this.adresseBien) {
        Object.assign(appointmentParams, {
          commentaire: `Nom vendeur: ${this.nomVendeur}, Adresse :${this.adresseBien}, Adresse :${this.adresseBien}, ${this.description}`,
        });
      }

      if (this.referenceAd) {
        Object.assign(appointmentParams, { ad: `/api/ads/${this.referenceAd}` });
      }

      this.$store.dispatch('calendar/addAppointment', appointmentParams);
      this.$store.commit('calendar/ADD_EVENT', {
        id: event,
        title: this.title,
        start: `${this.start} ${this.startTime}`,
        end: moment(`${this.start} ${this.startTime}`).add(Number(this.minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss'),
      });
    },
    updateMonth(val) {
      this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    clearFields() {
      this.title = '';
      this.end = '';
      this.url = '';
      this.id = 0;
      this.referenceAd = '';
      this.description = '';
      this.commande = '';
    },
    promptAddNewEvent(date) {
      this.newRdv = true;
      this.active = true;
      this.addNewEventDialog(date);
    },
    addNewEventDialog(date) {
      this.clearFields();
      this.start = date;
      this.startTime = moment(date).format('HH:mm');
      this.newRdv = true;
    },
    openAddNewEvent(date) {
      this.disabledFrom = true;
      this.addNewEventDialog(date);
    },
    async openEditEvent(event) {
      this.$vs.loading({ type: 'point' });

      this.newRdv = false;
      this.referenceAd = '';
      this.commande = '';
      this.whoUpdated = '';
      this.dateMiseAjour = '';
      this.rendezVous = null;
      this.active = true;

      const { data: eventAppointment } = await this.$http.get(`/api/evenement/getAppointment/${this.idNego}/${event.id}`);
      this.id = eventAppointment.id;
      this.title = eventAppointment.title;
      this.start = moment(eventAppointment.start).format('YYYY-MM-DD');
      this.startTime = moment(eventAppointment.start).format('HH:mm');
      this.end = eventAppointment.end;
      this.description = eventAppointment.desc;
      this.invId = eventAppointment.invId;
      this.minutes = moment.duration(moment(eventAppointment.end, 'YYYY/MM/DD HH:mm').diff(moment(eventAppointment.start, 'YYYY/MM/DD HH:mm'))).asMinutes();

      if (eventAppointment.rendezVous) {
        const { data: appointment } = await this.$http.get(`/api/rendez_vouses/${eventAppointment.rendezVous.id}`);
        this.rendezVous = appointment;
        this.eventForm.type.value = appointment.type;
        this.negociateurInfos = `${appointment.negociateur.firstName} ${appointment.negociateur.lastName}`;

        const { data: history } = await this.$http.get(`/api/historique_rdvs?rendezVous=${eventAppointment.rendezVous.id}&order[dateAction]=desc`);
        if (history.length) {
          this.whoUpdated = history[0].who;
          this.dateMiseAjour = moment(history[0].dateAction).format('YYYY-MM-DD HH:mm');
        }
      }
      this.$vs.loading.close();
    },
    async getAgentOrders(negoId) {
      const { data: mandataries } = await this.$http.get(`/api/mandataries/${negoId}`);
      const { data: commandes } = await this.$http.get(`/api/commandes?trigramme=${mandataries.trigramme}&statut=EC`);

      const fullName = `${mandataries.firstName} ${mandataries.lastName}`;
      this.trigrammeNego = mandataries.trigramme;
      this.nomNegociateur = fullName;
      this.negociateurInfos = fullName;

      this.commandesOptions = commandes.map(({ id, reference, formule }) => ({ key: id, value: `${reference} ${formule}` }));
      this.updateData();
    },
  },
  created() {
    this.$store.registerModule('calendar', moduleCalendar);
    if (this.idNego) {
      this.negociateur = this.idNego;
      this.updateData();
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule('calendar');
  },
};
</script>
